module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/LinkedLines/ddb-health/takeda/TMOGA/src/components/layout/MainLayout/MainLayout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr"],"defaultLanguage":"fr","siteUrl":"https://maladie-de-gaucher.ch/","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"dangerouslySetAllPagesToNoIndex":true,"dangerouslySetAllPagesToNoFollow":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
